import React from 'react';
import Header from './Header/Header';
import Header2 from './Header/Header2';
import Body from './Body/Body';
import Nav from '../Nav/Nav';
import Snav from '../Onama/Snav';

const Home = () => {
  return (
    <>
      <Header2 />
      <Body />
    </>
  );
};

export default Home;
